import { FC } from 'react';

import { SelectProps } from 'antd';

import { useDeepMemo } from '@apollo/client/react/hooks/internal';

import { useEmployeesQuery } from '@hooks/users/useEmployeesQuery';

import { UserSelect } from '@form/inputs/userSelect/UserSelect';

interface Props extends SelectProps {
  onChange?: (value: string) => void;
  defaultValue?: string;
  shouldApplyDefaultValue?: boolean;
  selectedIds?: Maybe<string>[];
  locationId?: string | null;
}

const EmployeeSelect: FC<Props> = ({
  onChange,
  defaultValue,
  shouldApplyDefaultValue = true,
  selectedIds,
  locationId,
  ...props
}) => {
  const baseQueryParams = useDeepMemo(() => {
    return {
      initialFilters: {
        ...(locationId
          ? {
              businessLocation: {
                id: {
                  eq: locationId,
                },
              },
            }
          : {}),
      },
    };
  }, [locationId, selectedIds]);

  const { data, loading, changeFilters } = useEmployeesQuery(baseQueryParams);

  const filteredData =
    data?.filter((user) => !selectedIds?.includes(user?.id)) || [];

  return (
    <UserSelect
      data={filteredData}
      changeFilters={changeFilters}
      onChange={onChange}
      loading={loading}
      defaultValue={
        shouldApplyDefaultValue ? defaultValue || data?.[0]?.id : undefined
      }
      onClear={() => onChange?.('')}
      value={props.value}
      placeholder={'Select the employee'}
      {...props}
    />
  );
};

export default EmployeeSelect;
