import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';
import { BusinessLocationsSelect } from '@ui/form/BusinessLocationsSelect';
import { ProductSelect } from '@ui/form/ProductSelect/ProductSelect';
import { DatePicker } from 'antd';
import { FC } from 'react';

export const InventoryAdjustmentAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => {
  return (
    <>
      <ExpandableFormItem
        name={'location'}
        label={'Business Location'}
        defaultOpen={!!values?.location}
      >
        <BusinessLocationsSelect
          placeholder={'Choose location'}
          storesOnly={false}
        />
      </ExpandableFormItem>

      <ExpandableFormItem
        name={'product'}
        label={'Product'}
        defaultOpen={!!values?.product}
        withDivider
      >
        <ProductSelect placeholder={'Choose product'} />
      </ExpandableFormItem>

      <ExpandableFormItem
        label={'Adjustment Date'}
        name={'adjustmentDate'}
        defaultOpen={!!values?.adjustmentDate}
        withDivider
      >
        <DatePicker style={{ width: '100%' }} />
      </ExpandableFormItem>

      <ExpandableFormItem
        label={'Date Created'}
        name={'createdAt'}
        defaultOpen={!!values?.createdAt}
        withDivider
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
    </>
  );
};
