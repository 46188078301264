import { useCreateMetalTypeMutation, useMetalTypesQuery } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { useCallback } from 'react';

interface ReturnType {
  createLoading: boolean;
  handleCreate: (name: string) => Promise<void>;
}

export function useMetalType(onChange: (values: string) => void): ReturnType {
  const message = useStatusMessage();

  const { data } = useMetalTypesQuery({
    variables: {
      pagination: {
        limit: -1,
      },
    },
  });

  const metalTypesArr = data?.metalTypes?.data?.map(
    (item) => item.attributes?.name,
  );

  const [createMetalType, { loading: createLoading }] =
    useCreateMetalTypeMutation({
      onError: () => message.open('error'),
      refetchQueries: ['metalTypes'],
    });

  const handleCreate = useCallback(
    async (name: string) => {
      if (metalTypesArr?.includes(name)) {
        message.open('error', 'This name already exist');
        return;
      }

      await createMetalType({
        variables: {
          input: {
            name,
          },
        },
        onCompleted: (data) => {
          if (data?.createMetalType?.data?.id) {
            onChange(data.createMetalType.data.id);
            message.open('success', 'Option added successful');
          }
        },
      });
    },
    [createMetalType, message, metalTypesArr, onChange],
  );

  return {
    createLoading,
    handleCreate,
  };
}
