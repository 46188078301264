import { FilterFunction } from '@filters/types';
import { transformToDateFilterInput } from '@helpers/filters';
import dayjs from 'dayjs';

export const createProductBarcodeFilter: FilterFunction<string> = (
  barcode?: string,
): ProductFiltersInput => {
  return barcode
    ? {
        id: {
          eq: barcode,
        },
      }
    : {};
};

export const createInventoryProductCreatedAtFilter = (
  createdAt?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): ProductFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};

export const createBusinessLocationProductFilter: FilterFunction<string[]> = (
  locationIds?: string[],
): ProductFiltersInput => {
  return locationIds?.length !== 0
    ? ({
        productOrderItems: {
          product: {
            businessLocation: {
              id: {
                in: locationIds,
              },
            },
          },
        },
      } as ProductFiltersInput)
    : {};
};
