import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const Selling = lazy<FC>(() => import('@/pages/selling'));
const SellingDashboard = lazy<FC>(() => import('@/pages/selling/dashboard'));
const OrderManagement = lazy<FC>(() => import('@/pages/selling/management'));
const POS = lazy<FC>(() => import('@/pages/selling/pos'));

const { index, dashboard, pos, management, layaway, rental, trade, purchase } =
  ROUTES.tenant.selling;

export const sellingRoutes: RouteObject = {
  Component: Selling,
  path: index,
  children: [
    {
      index: true,
      element: <Navigate to={dashboard} />,
    },
    {
      Component: SellingDashboard,
      path: dashboard,
    },
    {
      Component: POS,
      path: pos,
    },
    {
      Component: OrderManagement,
      path: management,
    },
    {
      Component: OrderManagement,
      path: layaway,
    },
    {
      Component: OrderManagement,
      path: rental,
    },
    {
      Component: OrderManagement,
      path: trade,
    },
    {
      Component: OrderManagement,
      path: purchase,
    },
  ],
};
