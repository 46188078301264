import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useAllContactsQuery } from '@/graphql';

import AvatarInfo from '@ui/avatar/AvatarInfo';

import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';
import { CustomOption } from '@form/inputs/userSelect/UserSelect';

import styles from './index.module.scss';

export type CustomerObject = {
  contact?: Maybe<string>;
  company?: Maybe<string>;
};
interface Props {
  onChange?: (value: CustomerObject) => void;
  initialValue?: Maybe<CustomerObject>;
  allowClear?: boolean;
  disabled?: boolean;
}

export const ContactsANDCompaniesSelect: FC<Props> = ({
  onChange,
  initialValue,
  allowClear = true,
  disabled,
}) => {
  const [contactFilters, setContactFilters] = useState<ContactFiltersInput>({});
  const [companyFilters, setCompanyFilters] = useState<CompanyFiltersInput>({});
  const queryParams = useMemo(
    () => ({
      pagination: {
        limit: 5,
      },
      filtersContacts: {
        ...contactFilters,
      },
      filtersCompanies: {
        ...companyFilters,
      },
    }),
    [contactFilters, companyFilters],
  );
  const { data, loading, refetch } = useAllContactsQuery({
    variables: {
      ...queryParams,
    },
  });

  const handleChange = useCallback(
    (value: string) => {
      const data = value.split(':');
      const inputData = {
        [data[0]]: data[1],
        [data[0] === 'contact' ? 'company' : 'contact']: null,
      };

      onChange?.(inputData);
    },
    [onChange],
  );

  useEffect(() => {
    refetch({ ...queryParams });
  }, [contactFilters, companyFilters, data, refetch, queryParams]);

  const individualContacts = useMemo(() => {
    const individualContactsData = data?.contacts?.data || [];

    return individualContactsData?.map((contact) => ({
      value: `contact:${contact?.id}`,
      title: contact?.attributes?.fullName || 'Unnamed',
      label: (
        <AvatarInfo
          title={contact?.attributes?.fullName || 'Unnamed'}
          src={contact?.attributes?.avatar?.data?.attributes?.url}
        />
      ),
    }));
  }, [data?.contacts?.data]);

  const companyContacts = useMemo(() => {
    const companiesData = data?.companies?.data || [];

    return companiesData?.map((contact) => ({
      value: `company:${contact?.id}`,
      title: contact?.attributes?.name || 'Unnamed',
      label: (
        <AvatarInfo
          title={contact?.attributes?.name || 'Unnamed'}
          src={contact?.attributes?.avatar?.data?.attributes?.url}
        />
      ),
    }));
  }, [data?.companies?.data]);

  const handleSearch = useCallback(
    (value: string) => {
      setContactFilters(
        value
          ? {
              or: [
                {
                  fullName: {
                    containsi: value,
                  },
                },
                {
                  email: {
                    containsi: value,
                  },
                },
                {
                  phoneNumber: {
                    containsi: value,
                  },
                },
              ],
            }
          : {},
      );
      setCompanyFilters(
        value
          ? {
              or: [
                {
                  name: {
                    containsi: value,
                  },
                },
                {
                  email: {
                    containsi: value,
                  },
                },
                {
                  phoneNumber: {
                    containsi: value,
                  },
                },
              ],
            }
          : {},
      );
    },
    [setContactFilters, setCompanyFilters],
  );

  const defaultValue = useMemo(() => {
    if (initialValue) {
      return `${initialValue.contact ? 'contact' : 'company'}:${
        initialValue.contact || initialValue.company
      }`;
    }
    return undefined;
  }, [initialValue]);

  return (
    <SearchSelect<string, CustomOption>
      placeholder={'Select Contact'}
      options={[...individualContacts, ...companyContacts]}
      optionLabelProp={'title'}
      onChange={handleChange}
      onSearch={handleSearch}
      defaultValue={defaultValue}
      allowClear={allowClear}
      loading={loading}
      style={{ width: '100%' }}
      className={styles.select}
      disabled={disabled}
    />
  );
};
