import { useCreateMaterialGradeMutation } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { useCallback } from 'react';

interface ReturnType {
  createLoading: boolean;
  handleCreate: (name: string) => Promise<void>;
}

export function useMaterialGrade(
  onChange: (values: string) => void,
  metalId: string,
): ReturnType {
  const message = useStatusMessage();

  const [createMaterialGrade, { loading: createLoading }] =
    useCreateMaterialGradeMutation({
      onError: () => message.open('error'),
      refetchQueries: ['materialGrades'],
    });

  const handleCreate = useCallback(
    async (name: string) => {
      await createMaterialGrade({
        variables: {
          input: {
            name,
            metalType: metalId,
          },
        },
        onCompleted: async (data) => {
          if (data?.createMaterialGrade?.data?.id) {
            onChange(data.createMaterialGrade.data.id);
            message.open('success', 'Option added successful');
          }
        },
      });
    },
    [createMaterialGrade, message, metalId, onChange],
  );

  return {
    createLoading,
    handleCreate,
  };
}
