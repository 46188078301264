import { useDataSelect } from '@inventory/inventoryManagement/products/inputs/useDataSelect';
import { SelectProps } from 'antd/lib';
import { FC, useEffect, useMemo } from 'react';

import { useMetalFinishTypesLazyQuery } from '@/graphql';
import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';
import { useCustomSelectLoader } from '@inventory/inventoryManagement/products/hooks/useCustomSelectLoader';
import { useMetalFinishType } from '@inventory/inventoryManagement/products/inputs/MetalFinishInput/useMetalFinishType';
import { AddNewSelectButton } from '@ui/form/AddNewSelectButton';
import { Skeleton } from 'antd';

interface Props extends SelectProps {
  onChange?: (value: string | null) => void;
  initialValue?: string;
  editable?: boolean;
}

export const MetalFinishTypeInput: FC<Props> = ({
  onChange,
  initialValue,
  editable = true,
}) => {
  const { handleChange, handleSearch, queryParams, selectedValue } =
    useDataSelect({
      onChange,
      initialSelectedValue: initialValue,
    });

  const { createLoading, handleCreate } = useMetalFinishType(handleChange);

  const [_, { data, refetch, loading }] = useMetalFinishTypesLazyQuery({
    variables: {
      ...queryParams,
      pagination: {
        limit: -1,
      },
      sort: ['createAt:asc'],
    },
  });
  const loader = useCustomSelectLoader(loading);
  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  const options = useMemo(() => {
    const types = data?.metalFinishTypes?.data || [];

    return types?.map((type) => ({
      value: type?.id,
      label: type?.attributes?.name,
    }));
  }, [data?.metalFinishTypes?.data]);

  if (loader) {
    return <Skeleton.Input active={true} size={'default'} block={true} />;
  }

  return (
    <SearchSelect
      allowClear
      defaultValue={initialValue}
      placeholder={`Select the metal finish type ${
        editable ? 'or create a new one' : ''
      }`}
      onChange={handleChange}
      value={selectedValue}
      options={options}
      loading={loading || createLoading}
      onSearch={handleSearch}
      dropdownRender={(menu) => (
        <>
          {menu}
          {editable && <AddNewSelectButton handleAddNew={handleCreate} />}
        </>
      )}
    />
  );
};
