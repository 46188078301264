import { toString } from 'lodash';

type StringObject = { [key: string]: any };
export const formatNumber = (
  value?: number | null,
  additionalOptions?: Intl.NumberFormatOptions,
): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...additionalOptions,
  }).format(value || 0);
};

export const formatToCurrency = (value?: number | null): string => {
  return formatNumber(value, { currency: 'USD', style: 'currency' });
};

export const formatToPercentage = (value?: number | null): string => {
  if (value == null) return '';

  return `${value.toFixed(2)}%`;
};

export const formatToDays = (value?: number | null): string => {
  if (value == null) return '';

  return `${value} ${value === 1 ? 'day' : 'days'}`;
};

export const convertToMegabytes = (
  value: number,
  unit: 'bytes' | 'kilobytes' = 'bytes',
): number => {
  if (unit === 'bytes') {
    return value / (1024 * 1024);
  } else if (unit === 'kilobytes') {
    return value / 1024;
  } else {
    throw new Error('Invalid unit');
  }
};

export const removeUnwantedChars = (inputString?: string): string => {
  if (!inputString) return '';
  const unwantedChars = /[\r\n\t\f]/g;
  return inputString ? toString(inputString).replace(unwantedChars, '') : '';
};

export const cleanObjectValues = (obj: StringObject) => {
  return Object.keys(obj).reduce((acc: StringObject, key) => {
    acc[key] =
      typeof obj[key] === 'string' ? removeUnwantedChars(obj[key]) : obj[key];
    return acc;
  }, {});
};

export const toCamelCase = (header: string): string => {
  return header
    .trim()
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const padArray = <T>(
  arr: T[],
  minLength: number,
  fillValue?: T,
): T[] => {
  return arr.length >= minLength
    ? arr
    : [...arr, ...new Array(minLength - arr.length).fill(fillValue)];
};

export const replaceUnderscoresWithSpaces = (arr: string[]) => {
  return arr.map((item) => item.replace(/_/g, ' '));
};
