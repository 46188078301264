import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { BarcodeSelect } from '@components/reports/inventory/BarcodeSelect';
import { AdditionalFieldProps } from '@filters/types';
import { BusinessLocationsSelect } from '@ui/form/BusinessLocationsSelect';

export const InventoryProductsAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => (
  <>
    <ExpandableFormItem
      name={'barcode'}
      label={'Barcode'}
      defaultOpen={!!values?.barcode}
      withDivider
    >
      <BarcodeSelect placeholder={'Filter by Barcode'} />
    </ExpandableFormItem>
    <ExpandableFormItem
      withDivider
      name={'locationIds'}
      label={'Business Location'}
      defaultOpen={!!values?.locationIds}
    >
      <BusinessLocationsSelect
        placeholder={'Choose location'}
        storesOnly={false}
      />
    </ExpandableFormItem>
  </>
);
