import dayjs from 'dayjs';

import {
  CrmFilters,
  CrmSingleEntityFiltersInput,
  FilterFunction,
} from '@filters/types';

export const createLeadSourceFilter: FilterFunction<string[]> = (
  leadSource?: string[],
): CrmSingleEntityFiltersInput => {
  return leadSource?.length !== 0
    ? {
        leadSource: {
          in: leadSource,
        },
      }
    : {};
};

export const createLeadStageFilter: FilterFunction<string[]> = (
  leadStage?: string[],
): CrmSingleEntityFiltersInput => {
  return leadStage?.length !== 0
    ? {
        leadStage: {
          in: leadStage,
        },
      }
    : {};
};

export const createLeadOwnerFilter: FilterFunction<string[]> = (
  leadOwner?: string[],
): CrmFilters => {
  return leadOwner?.length !== 0
    ? {
        leadOwner: {
          id: {
            in: leadOwner,
          },
        },
      }
    : {};
};

export const createCompanyFilter: FilterFunction<string[]> = (
  company?: string[],
): ContactFiltersInput => {
  return company?.length !== 0
    ? {
        company: {
          id: {
            in: company,
          },
        },
      }
    : {};
};

export const createActivityTypeFilter: FilterFunction<string[]> = (
  activityType?: string[],
): ActivityFiltersInput => {
  return activityType?.length !== 0
    ? {
        type: {
          in: activityType,
        },
      }
    : {};
};

export const createActivityDueDateFilter: FilterFunction<Date> = (
  dueDate?: Date,
): ActivityFiltersInput => {
  const date = dayjs(dueDate);

  const startOfDay = date.startOf('day');
  const endOfDay = date.endOf('day');

  return dueDate
    ? {
        and: [
          {
            due_date: {
              between: [startOfDay.toDate(), endOfDay.toDate()],
            },
          },
        ],
      }
    : {};
};

export const createActivityOwnerFilter: FilterFunction<string> = (
  owner?: string,
): ActivityFiltersInput => {
  return owner
    ? {
        and: [
          {
            owner: {
              id: {
                eq: owner,
              },
            },
          },
        ],
      }
    : {};
};
