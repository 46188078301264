import dayjs from 'dayjs';

import { FilterFunction } from '@filters/types';
import { transformToDateFilterInput } from '@helpers/filters';

export const createInventoryAdjustmentLocationFilters: FilterFunction<
  string
> = (location?: string): InventoryAdjustmentFiltersInput => ({
  location: {
    id: {
      eq: location,
    },
  },
});

export const createInventoryAdjustmentProductFilters: FilterFunction<string> = (
  product?: string,
): InventoryAdjustmentFiltersInput => ({
  inventoryAdjustmentItems: {
    product: {
      id: {
        eq: product,
      },
    },
  },
});

export const createInventoryAdjustmentDateOfAdjustmentFilter: FilterFunction<
  Date
> = (adjustmentDate?: Date): InventoryAdjustmentFiltersInput => {
  const date = dayjs(adjustmentDate);
  const startOfDay = date.startOf('day');
  const endOfDay = date.endOf('day');
  return adjustmentDate
    ? {
        adjustmentDate: {
          between: [startOfDay.toDate(), endOfDay.toDate()],
        },
      }
    : {};
};

export const createInventoryAdjustmentCreatedAtFilter = (
  createdAt?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): InventoryAdjustmentFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};
