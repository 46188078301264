import { useCreateGenderTypeMutation } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { useCallback } from 'react';

interface ReturnType {
  createLoading: boolean;
  handleCreate: (name: string) => Promise<void>;
}

export function useGenderType(onChange: (values: string) => void): ReturnType {
  const message = useStatusMessage();
  const [createGenderType, { loading: createLoading }] =
    useCreateGenderTypeMutation({
      onError: () => message.open('error'),
      refetchQueries: ['genderTypes'],
    });

  const handleCreate = useCallback(
    async (name: string) => {
      await createGenderType({
        variables: {
          input: {
            name,
          },
        },
        onCompleted: (data) => {
          if (data?.createGenderType?.data?.id) {
            onChange(data.createGenderType.data.id);
            message.open('success', 'Option added successful');
          }
        },
      });
    },
    [createGenderType, message, onChange],
  );

  return {
    createLoading,
    handleCreate,
  };
}
