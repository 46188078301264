import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

const monthMap: Record<string, string> = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
};

export const getFormattedDate = (date?: string | number | Date) => {
  if (!date) {
    return null;
  }

  return Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(new Date(date));
};

export const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current < dayjs().startOf('day');
};

export const currentDate = new Date();

export const lastMonthSameDay = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() - 1,
  currentDate.getDate(),
);

export const lastYearSameDay = new Date(
  currentDate.getFullYear() - 1,
  currentDate.getMonth(),
  currentDate.getDate(),
);

export const beginningOfToday = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  currentDate.getDate(),
);

export const beginningOfTomorrow = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  currentDate.getDate() + 1,
);

export const beginningOfThisMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  1,
);

export const beginningOfLastMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() - 1,
  1,
);

export const beginningOfThisYear = new Date(currentDate.getFullYear(), 0, 1);

export const beginningOfLastYear = new Date(
  currentDate.getFullYear() - 1,
  0,
  1,
);

export const getTodayDateRange = () => {
  return {
    between: [
      beginningOfToday.toISOString(),
      beginningOfTomorrow.toISOString(),
    ],
  };
};

export const getDateRange = (year: Maybe<string>, month: Maybe<string>) => {
  let startDate, endDate;

  if (year && month) {
    const numericMonth = monthMap[month];
    startDate = new Date(`${year}-${numericMonth}-01`);
    endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 1);
  } else if (year) {
    startDate = new Date(`${year}-01-01`);
    endDate = new Date(`${parseInt(year) + 1}-01-01`);
  } else {
    startDate = new Date(currentDate.getFullYear(), 0, 1);
    endDate = new Date(currentDate.getFullYear() + 1, 0, 1);
  }

  return {
    between: [startDate.toISOString(), endDate.toISOString()],
  };
};
