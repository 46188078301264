import { FC, useCallback, useMemo } from 'react';

import dayjs from 'dayjs';

import { useStatusMessage } from '@/components/app/StatusMessageContext/statusMessageContext';
import DrawerFormWrapper from '@/components/crm/formWrapper';
import { useDealQuery, useUpdateDealMutation } from '@/graphql';

import { Loader } from '@components/layout/MainLayout';

import { useCustomForm } from '@form/hooks/useCustomForm';

import DealInputsList from '@crm/profile/deals/DealInputsList';

export type DealInputWithAllContacts = DealInput & {
  allContacts: {
    contact: string | null;
    company: string | null;
    lead: string | null;
  };
};

export interface Props {
  onCancel: () => void;
  id: string;
  location: string;
}

const UpdateDealsForm: FC<Props> = ({ onCancel, id, location }) => {
  const [form] = useCustomForm<DealInputWithAllContacts>();
  const message = useStatusMessage();
  const [updateDeal, { loading }] = useUpdateDealMutation({
    refetchQueries: ['deals'],
  });

  const { data: deal, loading: loadingDeal } = useDealQuery({
    variables: { id },
  });

  const dealAttributes = deal?.deal?.data?.attributes;

  const defaultOptions = useMemo(() => {
    return {
      name: dealAttributes?.name,
      stage: dealAttributes?.stage,
      startDate: dayjs(dealAttributes?.startDate),
      budget: dealAttributes?.budget,
      notes: dealAttributes?.notes,
    };
  }, [dealAttributes]);

  const handleEditeDeal = useCallback(
    async (variables: DealInputWithAllContacts) => {
      const { allContacts, ...rest } = variables;
      await updateDeal({
        variables: {
          id: id,
          input: {
            ...rest,
            ...allContacts,
          },
        },
        onCompleted: () => {
          message?.open('success', 'Deal updated successfully!');
          form.resetFields();
          onCancel();
        },
        onError: () => message?.open('error'),
      });
    },
    [form, id, message, onCancel, updateDeal],
  );

  return loadingDeal ? (
    <Loader spinning />
  ) : (
    <DrawerFormWrapper<DealInputWithAllContacts>
      requiredMark={false}
      onFinish={handleEditeDeal}
      onCancel={onCancel}
      initialValues={defaultOptions}
      form={form}
      primaryAction={loading ? 'Loading' : `Update Deal`}
    >
      <DealInputsList location={location} />
    </DrawerFormWrapper>
  );
};

export default UpdateDealsForm;
