import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { OrderIdSelect } from '@components/accounting/transactions/ui/selects/OrderIdSelect';
import { getIsProductFilterOpen } from '@filters/helpers/getIsProductFilterOpen';
import { useRenderSalesItemCustomFields } from '@filters/helpers/renderSalesItemFieldsHelper';
import { AdditionalFieldProps } from '@filters/types';
import { useCustomForm } from '@form/hooks/useCustomForm';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { CustomFormItem } from '@form/item/FormItem';
import { useProductFormWatch } from '@inventory/inventoryManagement/products/hooks/useProductFormWatch';
import { ConditionInput } from '@inventory/inventoryManagement/products/inputs/ConditionInput';
import { EngravingTypeInput } from '@inventory/inventoryManagement/products/inputs/EngravingTypeInput';
import { GenderInput } from '@inventory/inventoryManagement/products/inputs/GenderInput';
import { ManufacturingProcessInput } from '@inventory/inventoryManagement/products/inputs/ManufacturingProcessInput';
import { MaterialGradeInput } from '@inventory/inventoryManagement/products/inputs/MaterialGradeInput';
import { MetalFinishTypeInput } from '@inventory/inventoryManagement/products/inputs/MetalFinishInput';
import { MetalTypeInput } from '@inventory/inventoryManagement/products/inputs/MetalTypeInput';
import { PlattingTypeInput } from '@inventory/inventoryManagement/products/inputs/PlattingTypeInput';
import { ProductTypeSelect } from '@inventory/inventoryManagement/products/inputs/PruductTypeInput';
import { SubLocationSelectInput } from '@inventory/inventoryManagement/products/inputs/SublocationSelect';
import { TimePeriodInput } from '@inventory/inventoryManagement/products/inputs/TimePeriodInput';
import { ProductValues } from '@inventory/inventoryManagement/products/types';
import { BusinessLocationsSelect } from '@ui/form/BusinessLocationsSelect';
import { ClassSelect } from '@ui/form/ClassSelect/ClassSelect';
import { CompositeProductSelect } from '@ui/form/CompositeProductSelect/CompositeProductSelect';
import EmployeeSelect from '@ui/form/EmployeeSelect';
import { MembershipSelect } from '@ui/form/MembershipSelect/MembershipSelect';
import { ProductSelect } from '@ui/form/ProductSelect/ProductSelect';
import { SalesItemReportTypeSelect } from '@ui/form/SalesItemReportTypeSelect';
import { ServiceSelect } from '@ui/form/ServiceSelect/ServiceSelect';
import { Col, Row } from 'antd';

const customInputStyles = { margin: 0 };

export const SalesItemAdditionalFields: FC<
  AdditionalFieldProps & { entityName?: string }
> = ({ values, entityName }) => {
  const { productType, metalType } = useProductFormWatch();
  const [form, handleChange] = useCustomForm<ProductValues>();
  const salesItemCustomFields = useRenderSalesItemCustomFields(
    productType,
    handleChange,
    values,
    customInputStyles,
  );
  const isProductFilterOpen = getIsProductFilterOpen(values);

  return (
    <>
      <ExpandableFormItem
        name={'orderId'}
        defaultOpen={!!values?.orderId}
        label={`Order Id`}
      >
        <OrderIdSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        name={'type'}
        label={'Item Type'}
        defaultOpen={!!values?.type}
      >
        <SalesItemReportTypeSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Product'}
        defaultOpen={isProductFilterOpen}
      >
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <CustomFormItem
              name={'productId'}
              label={'Product'}
              style={customInputStyles}
            >
              <ProductSelect value={values?.productId} />
            </CustomFormItem>
          </Col>

          <Col span={24}>
            <CustomFormItem
              name={'conditionType'}
              label={'Condition'}
              style={customInputStyles}
            >
              <ConditionInput
                onChange={handleChange('conditionType')}
                initialValue={values?.conditionType ?? undefined}
                editable={false}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'genderType'}
              label={'Gender'}
              style={customInputStyles}
            >
              <GenderInput
                onChange={handleChange('genderType')}
                initialValue={values?.genderType ?? undefined}
                editable={false}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'process'}
              label={'Manufacturing Process'}
              style={customInputStyles}
            >
              <ManufacturingProcessInput
                onChange={handleChange('process')}
                initialValue={values?.process ?? undefined}
                editable={false}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'metalFinishType'}
              label={'Metal Finish'}
              style={customInputStyles}
            >
              <MetalFinishTypeInput
                onChange={handleChange('metalFinishType')}
                initialValue={values?.metalFinishType ?? undefined}
                editable={false}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'platting'}
              label={'Platting'}
              style={customInputStyles}
            >
              <PlattingTypeInput
                onChange={handleChange('platting')}
                initialValue={values?.platting ?? undefined}
                editable={false}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'metalType'}
              label={'Metal'}
              style={customInputStyles}
            >
              <MetalTypeInput
                onChange={handleChange('metalType')}
                initialValue={values?.metalType ?? undefined}
                editable={false}
              />
            </CustomFormItem>
          </Col>
          {metalType && (
            <Col span={24}>
              <CustomFormItem
                name={'materialGradeType'}
                label={'Material Grade'}
                style={customInputStyles}
              >
                <MaterialGradeInput
                  disabled={!metalType}
                  onChange={handleChange('materialGradeType')}
                  initialValue={values?.materialGradeType ?? undefined}
                  metalId={metalType}
                  editable={false}
                />
              </CustomFormItem>
            </Col>
          )}
          <Col span={24}>
            <CustomFormItem
              name={'engravingType'}
              label={'Stamped/Engraved'}
              style={customInputStyles}
            >
              <EngravingTypeInput
                onChange={handleChange('engravingType')}
                initialValue={values?.engravingType ?? undefined}
                editable={false}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'timePeriod'}
              label={'Time Period'}
              style={customInputStyles}
            >
              <TimePeriodInput
                onChange={handleChange('timePeriod')}
                initialValue={values?.timePeriod ?? undefined}
                editable={false}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'productType'}
              label={'Product Type'}
              style={customInputStyles}
            >
              <ProductTypeSelect
                initialValue={values?.productType}
                editable={false}
                onChange={handleChange('productType')}
              />
            </CustomFormItem>
          </Col>

          {salesItemCustomFields}
        </Row>
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Composite Product'}
        name={'compositeProductId'}
        defaultOpen={!!values?.compositeProductId}
      >
        <CompositeProductSelect value={values?.compositeProductId} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Service'}
        name={'serviceId'}
        defaultOpen={!!values?.serviceId}
      >
        <ServiceSelect value={values?.serviceId} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Membership'}
        name={'membershipId'}
        defaultOpen={!!values?.membershipId}
      >
        <MembershipSelect value={values?.membershipId} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Class'}
        name={'classId'}
        defaultOpen={!!values?.classId}
      >
        <ClassSelect value={values?.classId} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Business Location'}
        defaultOpen={!!values?.locationIds || !!values?.sublocation}
      >
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <CustomFormItem
              label={'Business Location'}
              name={'locationIds'}
              style={customInputStyles}
            >
              <BusinessLocationsSelect placeholder={'Choose location'} />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              label={'Sublocation'}
              name={'sublocation'}
              style={customInputStyles}
            >
              <SubLocationSelectInput
                businessLocationId={values?.locationIds}
                initialValue={values?.sublocation ?? ''}
                editable={false}
              />
            </CustomFormItem>
          </Col>
        </Row>
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        name={'sales'}
        label={'Employee'}
        defaultOpen={!!values?.sales}
      >
        <EmployeeSelect shouldApplyDefaultValue={false} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Due Date'}
        name={'dueDate'}
        defaultOpen={!!values?.dueDate}
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
    </>
  );
};
