import dayjs from 'dayjs';

import { FilterFunction } from '@filters/types';

export const createPriorityTypeFilter: FilterFunction<string> = (
  priority?: string,
): TaskFiltersInput => {
  return priority
    ? {
        priority: {
          eq: priority,
        },
      }
    : {};
};

export const createAssigneesFilter: FilterFunction<string[]> = (
  assignees?: string[],
): TaskFiltersInput => {
  return assignees?.length !== 0
    ? {
        assignees: {
          id: {
            in: assignees,
          },
        },
      }
    : {};
};

export const createTasksDueDateFilter: FilterFunction<Date> = (
  dueDate?: Date,
): TaskFiltersInput => {
  const date = dayjs(dueDate);
  const startOfDay = date.startOf('day');
  const endOfDay = date.endOf('day');

  return dueDate
    ? {
        and: [
          {
            dueDate: {
              between: [startOfDay.toDate(), endOfDay.toDate()],
            },
          },
        ],
      }
    : {};
};

export const createCustomersFilters = ({
  customer,
}: {
  customer?: {
    contact: string | null;
    lead: string | null;
    company: string | null;
  };
}) => {
  return customer
    ? {
        contact: {
          id: {
            eq: customer?.contact ?? undefined,
          },
        },
        company: {
          id: {
            eq: customer?.company ?? undefined,
          },
        },
        lead: {
          id: {
            eq: customer?.lead ?? undefined,
          },
        },
      }
    : {};
};

export const createApprovalMethodFilter: FilterFunction<string> = (
  approvalMethod?: string,
): TaskFiltersInput => {
  return approvalMethod
    ? {
        approvalMethods: {
          id: {
            eq: approvalMethod,
          },
        },
      }
    : {};
};

export const createForCompanyFilter: FilterFunction<string> = (
  forCompany?: string,
): TaskFiltersInput => {
  return forCompany
    ? {
        forCompanies: {
          id: {
            eq: forCompany,
          },
        },
      }
    : {};
};
export const createTaskTypeFilter: FilterFunction<string> = (
  taskType?: string,
): TaskFiltersInput => {
  return taskType
    ? {
        taskType: {
          id: {
            eq: taskType,
          },
        },
      }
    : {};
};

export const createTaskLocationFilter: FilterFunction<string> = (
  taskLocation?: string,
): TaskFiltersInput => {
  return taskLocation
    ? {
        taskLocation: {
          id: {
            eq: taskLocation,
          },
        },
      }
    : {};
};
