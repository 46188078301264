import { useCreateStrandMutation } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { useCallback } from 'react';

interface ReturnType {
  createLoading: boolean;
  handleCreate: (name: string) => Promise<void>;
}

export function useStrand(
  onChange: (values: string) => void,
  typeId: string,
): ReturnType {
  const message = useStatusMessage();
  const [createStrand, { loading: createLoading }] = useCreateStrandMutation({
    onError: () => message.open('error'),
    refetchQueries: ['strands'],
  });

  const handleCreate = useCallback(
    async (name: string) => {
      await createStrand({
        variables: {
          input: {
            name,
            productType: typeId,
          },
        },
        onCompleted: (data) => {
          if (data?.createStrand?.data?.id) {
            onChange(data.createStrand.data.id);
            message.open('success', 'Option added successful');
          }
        },
      });
    },
    [createStrand, message, onChange, typeId],
  );

  return {
    createLoading,
    handleCreate,
  };
}
