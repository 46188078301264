import { useCreateEngravingTypeMutation } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { useCallback } from 'react';

interface ReturnType {
  createLoading: boolean;
  handleCreate: (name: string) => Promise<void>;
}

export function useEngravingType(
  onChange: (values: string) => void,
): ReturnType {
  const message = useStatusMessage();
  const [createEngravingType, { loading: createLoading }] =
    useCreateEngravingTypeMutation({
      onError: () => message.open('error'),
      refetchQueries: ['engravingTypes'],
    });

  const handleCreate = useCallback(
    async (name: string) => {
      await createEngravingType({
        variables: {
          input: {
            name,
          },
        },
        onCompleted: (data) => {
          if (data?.createEngravingType?.data?.id) {
            onChange(data.createEngravingType.data.id);
            message.open('success', 'Option added successful');
          }
        },
      });
    },
    [createEngravingType, message, onChange],
  );

  return {
    createLoading,
    handleCreate,
  };
}
