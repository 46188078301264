import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { usePlatformRoutes } from '@router/routes';

import { removeAuthToken } from '@helpers/auth';

export const useSignOut = () => {
  const navigate = useNavigate();

  const routes = usePlatformRoutes();

  return useCallback(
    async (redirect?: string) => {
      removeAuthToken();
      navigate(redirect || routes.auth.signIn);
    },
    [navigate, routes.auth.signIn],
  );
};
