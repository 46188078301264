import { SalesItemReportFilterCustomFields } from '@filters/types';
import { CustomFormItem } from '@form/item/FormItem';
import { ProductBrandSelect } from '@inventory/inventoryManagement/products/inputs/ProductBrandInput';
import { Col } from 'antd';
import { FC } from 'react';
import { BoxPapersInput } from 'src/components/inventory/inventoryManagement/products/inputs/BoxPapersInput';
import { CountriesInput } from 'src/components/inventory/inventoryManagement/products/inputs/CountriesInput';
import { SpecificTypeInput } from 'src/components/inventory/inventoryManagement/products/inputs/SpecificTypeInput';

export const WatchFilterCustomFields: FC<SalesItemReportFilterCustomFields> = ({
  initialValues,
  handleChange,
  customStyles,
}) => {
  return (
    <>
      <Col span={24}>
        <CustomFormItem
          name={'brand'}
          label={'Watch Brand'}
          style={customStyles}
        >
          <ProductBrandSelect
            onChange={handleChange('brand')}
            initialValue={initialValues?.brand ?? ''}
            editable={false}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'specificType'}
          label={'Watch Type'}
          style={customStyles}
        >
          <SpecificTypeInput
            onChange={handleChange('specificType')}
            initialValue={initialValues?.specificType ?? ''}
            editable={false}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem name={'country'} label={'Country'} style={customStyles}>
          <CountriesInput
            onChange={handleChange('country')}
            initialValue={initialValues?.country ?? ''}
            editable={false}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'boxPaper'}
          label={'Box and Papers'}
          style={customStyles}
        >
          <BoxPapersInput
            onChange={handleChange('boxPaper')}
            initialValue={initialValues?.boxPaper ?? ''}
            editable={false}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
