import { FC } from 'react';

import { PriorityType } from '@helpers/enumTypes';
import { Space } from 'antd';

interface Props {
  label: PriorityType;
}

const getOptionColor = (type: PriorityType) => {
  switch (type) {
    case PriorityType.HIGH:
      return '#C62421';
    case PriorityType.MEDIUM:
      return '#D37D21';
    case PriorityType.LOW:
      return '#077C38';
    case PriorityType.INACTIVE:
      return '#A6B7ED';
    case PriorityType.ONGOING:
      return '#001C72';
    default:
      return undefined;
  }
};
export const PrioritySelectOption: FC<Props> = ({ label }) => (
  <Space>
    <div
      style={{
        width: '16px',
        height: '16px',
        borderRadius: '4px',
        backgroundColor: getOptionColor(label),
      }}
    />
    {label}
  </Space>
);
