import React from 'react';

import { ConfigProvider, Typography } from 'antd';

interface ConditionalTitleProps {
  value: string | number;
  copyable?: boolean;
}

const RenderId: React.FC<ConditionalTitleProps> = React.memo(
  ({ value, copyable }) => {
    return value ? (
      <ConfigProvider
        theme={{
          components: {
            Typography: {
              fontSizeHeading5: 12,
              fontWeightStrong: 500,
            },
          },
        }}
      >
        <Typography.Title copyable={!!copyable} level={5}>
          {value}
        </Typography.Title>
      </ConfigProvider>
    ) : null;
  },
);

export default RenderId;
