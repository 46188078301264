import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useContactsQuery } from '@/graphql';
import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';
import { CustomOption } from '@form/inputs/userSelect/UserSelect';
import { SelectProps } from 'antd';

interface Props extends SelectProps {
  onChange?: (value: string | string[]) => void;
  mode?: 'multiple' | 'tags';
  placeholder?: string;
  defaultValue?: string | string[];
  initFilters?: ContactFiltersInput;
}

const ContactsSelect: FC<Props> = ({
  onChange,
  mode,
  placeholder,
  defaultValue,
  initFilters = {},
  ...props
}) => {
  const [filters, setFilters] = useState<ContactFiltersInput>();
  const queryParams = useMemo(
    () => ({
      pagination: {
        limit: 10,
      },
      filters: {
        ...initFilters,
        ...filters,
      },
    }),
    [filters, initFilters],
  );
  const { data, loading, refetch } = useContactsQuery({
    variables: {
      ...queryParams,
    },
  });

  const handleChange = useCallback(
    (value: string | string[]) => {
      onChange?.(value);
    },
    [onChange],
  );

  useEffect(() => {
    refetch({ ...queryParams });
  }, [filters, data, refetch, queryParams]);

  const contactsData = data?.contacts?.data || [];

  const contacts = contactsData?.map((contact) => ({
    title: contact?.attributes?.fullName,
    value: contact?.id,
    label: contact?.attributes?.fullName,
  })) as CustomOption[];

  const handleSearch = useCallback(
    (value: string) => {
      setFilters(
        value
          ? {
              or: [
                {
                  fullName: {
                    containsi: value,
                  },
                },
                {
                  email: {
                    containsi: value,
                  },
                },
              ],
            }
          : {},
      );
    },
    [setFilters],
  );

  return (
    <SearchSelect<string | string[], CustomOption>
      placeholder={placeholder ?? 'Select Contact'}
      options={contacts}
      optionLabelProp={'title'}
      onChange={handleChange}
      onSearch={handleSearch}
      allowClear
      loading={loading}
      mode={mode}
      defaultValue={defaultValue}
      disabled={props.disabled}
    />
  );
};

export default ContactsSelect;
