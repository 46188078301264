import { useCreatePlattingTypeMutation } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { useCallback } from 'react';

interface ReturnType {
  createLoading: boolean;
  handleCreate: (name: string) => Promise<void>;
}

export function usePlattingType(
  onChange: (values: string) => void,
): ReturnType {
  const message = useStatusMessage();
  const [createPlattingType, { loading: createLoading }] =
    useCreatePlattingTypeMutation({
      onError: () => message.open('error'),
      refetchQueries: ['plattingTypes'],
    });

  const handleCreate = useCallback(
    async (name: string) => {
      await createPlattingType({
        variables: {
          input: {
            name,
          },
        },
        onCompleted: (data) => {
          if (data?.createPlattingType?.data?.id) {
            onChange(data.createPlattingType.data.id);
            message.open('success', 'Option added successful');
          }
        },
      });
    },
    [createPlattingType, message, onChange],
  );

  return {
    createLoading,
    handleCreate,
  };
}
