import { FC, useEffect, useState } from 'react';

import { SelectProps } from 'antd/lib';

import { useRolesQuery } from '@/graphql';

import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';

interface Props extends SelectProps {
  onChange?: (value: string) => void;
}

export const UserRolesSelect: FC<Props> = ({ onChange, ...props }) => {
  const [filters, setFilters] = useState<UsersPermissionsRoleFiltersInput>({});
  const { data, loading, refetch } = useRolesQuery({
    variables: { filters: filters },
  });

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  const roles = data?.usersPermissionsRoles?.data || [];

  const rolesItems = roles
    ?.map((role) => {
      if (role?.attributes?.type !== 'employee') return null; // TODO: Lesya - Update this condition when permissions flow is ready

      return {
        value: role?.id,
        label: role?.attributes?.name,
      };
    })
    .filter((item) => item !== null);

  const handleSearch = (value: string) => {
    setFilters({
      name: {
        containsi: value,
      },
    });
  };

  return (
    <SearchSelect
      placeholder={'Select the team'}
      onChange={onChange}
      options={rolesItems as SelectProps['options']}
      loading={loading}
      onSearch={handleSearch}
      {...props}
    />
  );
};
