import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';
import { Input, Select } from 'antd';
import { FC } from 'react';
const selectOptions = [
  {
    key: 1,
    value: true,
    label: 'Success',
  },
  {
    key: 2,
    value: false,
    label: 'Wainting',
  },
];
export const ContractFormsAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => {
  return (
    <>
      <ExpandableFormItem
        label={'Form name'}
        name={'name'}
        defaultOpen={!!values?.name}
      >
        <Input />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Customer Name'}
        name={'customerName'}
        defaultOpen={!!values?.customerName}
        withDivider
      >
        <Input />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Sent to'}
        name={'sendTo'}
        defaultOpen={!!values?.sendTo}
        withDivider
      >
        <Input />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Form status'}
        name={'submitted'}
        defaultOpen={!!values?.submitted}
        withDivider
      >
        <Select options={selectOptions} />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Date Created'}
        name={'createdAt'}
        defaultOpen={!!values?.createdAt}
        withDivider
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
    </>
  );
};
