import { useCreateProductBrandMutation } from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { useCallback } from 'react';

interface ReturnType {
  createLoading: boolean;
  handleCreate: (name: string) => Promise<void>;
}

export function useProductBrand(
  onChange: (values: string) => void,
): ReturnType {
  const message = useStatusMessage();
  const [createProductBrand, { loading: createLoading }] =
    useCreateProductBrandMutation({
      onError: () => message.open('error'),
      refetchQueries: ['productBrands'],
    });

  const handleCreate = useCallback(
    async (name: string) => {
      await createProductBrand({
        variables: {
          input: {
            name,
          },
        },
        onCompleted: (data) => {
          if (data?.createProductBrand?.data?.id) {
            onChange(data.createProductBrand.data.id);
            message.open('success', 'Option added successful');
          }
        },
      });
    },
    [createProductBrand, message, onChange],
  );

  return {
    createLoading,
    handleCreate,
  };
}
